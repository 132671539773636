<template>
  <team-member-profile
    name="Dorota Laska-Gaffney"
    qualifications="BSc(Hons) MSc(Cerebral Palsy)"
    charter="MSC. MCSP"
    registration="HCPC Registered: PH: 85578"
    :pic="require(`@/assets/images/dorota.jpg`)"
  >
    <p>
      Dorota qualified as a physiotherapist in 2002 with a Masters degree and
      has worked in the NHS for nearly fifteen years now, as well as spending
      three years abroad gaining further neurological physiotherapy experience.
      The latter included six months of intensive neurological training in the
      United States. During her time in university she developed a keen interest
      in neurology and completed her dissertation in the management of cerebral
      palsy. After graduation Dorota has worked as a neurological
      physiotherapist in a private neurological clinic providing specialist
      assessment and treatment to patients with multiple sclerosis, brain
      injury, and stroke, and this led to her decision to specialise in
      neurological physiotherapy.
    </p>
    <p>
      Since 2009 Dorota has worked within the Southern Health NHS Foundation
      trust in a variety of clinical settings: inpatients, clinics, and the
      community. For the past six years Dorota has worked in the Neuro Active
      Living Clinic in the Gosport War Memorial Hospital, gaining extensive
      experience in the management of long term complex neurological conditions.
      She has worked in multidisciplinary settings, facilitating patients to
      achieve their personal goals and regain their independence.
    </p>
    <p>
      Dorota is very committed to providing patient-centered neurorehabilitation
      for patients with stroke, multiple sclerosis, Parkinson's disease,
      cerebral palsy, and motor neurone disease. Dorota aims to increase the
      quality of life of people with neurological conditions and unlock the
      patient's potential. She has completed post graduate courses including:
      Advanced Proprioceptive Neurological Facilitation (six months), Advanced
      Bobath, Postural Stability Instructor. She also imparted her clinical
      skills and knowledge by lecturing at Chichester and Southampton
      Universities.
    </p>
    <p>
      Dorota enjoys running, skiing and keeping fit; loves gardening, garden
      design and salsa dancing.
    </p>
  </team-member-profile>
</template>

<script>
import TeamMemberProfile from "../../layouts/TeamMemberProfile.vue";
export default {
  name: "Dorota",
  components: {
    "team-member-profile": TeamMemberProfile,
  },
};
</script>
